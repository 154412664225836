import React from 'react'

import HeadingComponent from '../../../ComponentsV2/common/HeadingComponent'
import OptimizedImage from '../../../ComponentsV2/common/OptimizedImage'
import {Section} from '../../../ComponentsV2/common/Section'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {ProcessStepsContainer} from './ProcessSteps.styles'

function ProcessSteps(props) {
  const {
    headingLevel = 2,
    steps = [],
    title = '',
    sectionName = 'processSteps',
    themeToUse = '',
  } = props.state || {}

  if (!(steps?.length > 0)) {
    return null
  }

  return (
    <ProcessStepsContainer
      data-section={sectionName}
      data-category={themeToUse}
    >
      <Section state={{title, headingLevel}} />
      <div className="process-steps">
        {steps.map((step, index) => {
          const {
            image = '',
            label = '',
            number = '',
            altText = '',
            headingLevel = '3',
          } = step

          return (
            <div key={index} className="step-container">
              <div className="step-data">
                <OptimizedImage
                  source={image}
                  aspectWidth="100"
                  aspectHeight="100"
                  desktopViewWidth="THIRD"
                  mobileViewWidth="SMALL"
                  altText={altText}
                  loading="eager"
                  customClassName="step-image"
                />
                <div className="step-label-container">
                  <HeadingComponent
                    customClassName="step-label"
                    headingLevel={headingLevel}
                    label={label}
                  />
                  <div className="step-number">{number}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </ProcessStepsContainer>
  )
}

export default withCustomErrorBoundary(ProcessSteps, 'ProcessSteps')
