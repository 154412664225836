import noop from 'lodash/noop'
import React, {useMemo} from 'react'

import Responsive from '../../../../ComponentsV2/HOC/Responsive'
import HeadingComponent from '../../../../ComponentsV2/common/HeadingComponent'
import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {GetRegimeCardContainer} from './GetRegimeCard.styles'

function GetRegimeCard(props) {
  const {state = {}, actions = {}, isMobile = false} = props || {}
  const {
    headingLevel = 2,
    title = '',
    description = '',
    productImage = '',
    arrowImage = '',
    backgroundImageDesktop = '',
    backgroundImageMobile = '',
  } = state
  const {onCardClick = noop} = actions

  const imageToShow = useMemo(
    () => (isMobile ? backgroundImageMobile : backgroundImageDesktop),
    [backgroundImageDesktop, backgroundImageMobile, isMobile]
  )

  return (
    <GetRegimeCardContainer onClick={onCardClick}>
      <div className="content-left">
        <OptimizedImage
          source={productImage}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          alt="product image"
          loading="lazy"
          customClassName="product-image"
        />
        <div className="content">
          <HeadingComponent
            headingLevel={headingLevel}
            customClassName="title"
            label={title}
          />
          <p className="description">{description}</p>
        </div>
      </div>
      <OptimizedImage
        source={arrowImage}
        aspectWidth="36"
        aspectHeight="36"
        desktopViewWidth="SMALL"
        mobileViewWidth="SMALL"
        alt="arrow"
        loading="lazy"
        customClassName="arrow-image"
      />
      <OptimizedImage
        source={imageToShow}
        desktopViewWidth="FULL"
        mobileViewWidth="FULL"
        alt="background card"
        loading="lazy"
        customClassName="background-image"
      />
    </GetRegimeCardContainer>
  )
}

export default Responsive(GetRegimeCard)
