import isEmpty from 'lodash/isEmpty'
import React, {useMemo} from 'react'

import Responsive from '../../../ComponentsV2/HOC/Responsive'
import HeadingComponent from '../../../ComponentsV2/common/HeadingComponent'
import OptimizedImage from '../../../ComponentsV2/common/OptimizedImage'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {StyledHeroBannerWrapper} from './HeroBanner.styles'

const HeroBanner = (props) => {
  const {state = {}, isMobile = false} = props || {}

  const {
    headingLevel = 1,
    subHeadingLevel = 3,
    title = '',
    subtitle = '',
    imageDesktop = '',
    imageMobile = '',
    altText = '',
    width,
    desktopWidth,
    height,
    desktopHeight,
  } = state || {}

  const bannerImage = useMemo(() => {
    return isMobile ? imageMobile : imageDesktop
  }, [imageDesktop, imageMobile, isMobile])

  const [renderedImageWidth, renderedImageHeight] = useMemo(() => {
    if (isMobile) {
      return [width, height]
    }

    return [desktopWidth, desktopHeight]
  }, [isMobile, desktopWidth, desktopHeight, width, height])

  if (isEmpty(props?.state)) {
    return null
  }

  return (
    <StyledHeroBannerWrapper>
      {bannerImage && (
        <OptimizedImage
          source={bannerImage}
          aspectWidth={renderedImageWidth}
          aspectHeight={renderedImageHeight}
          desktopViewWidth="FULL"
          mobileViewWidth="FULL"
          altText={altText}
          loading="lazy"
          customClassName="hero-banner__image"
        />
      )}
      <div className="hero-banner__title-container">
        <HeadingComponent
          headingLevel={headingLevel}
          customClassName="hero-banner__title"
          label={title}
        />
        <HeadingComponent
          headingLevel={subHeadingLevel}
          customClassName="hero-banner__subtitle"
          label={subtitle}
        />
      </div>
    </StyledHeroBannerWrapper>
  )
}
export default Responsive(withCustomErrorBoundary(HeroBanner, 'HeroBanner'))
