import React from 'react'

import {EmptyResultsText} from './EmptyResults.styles'

function EmptyResults(props) {
  const {state = {}} = props
  const {regimeFallback = ''} = state

  return <EmptyResultsText dangerouslySetInnerHTML={{__html: regimeFallback}} />
}

export default EmptyResults
