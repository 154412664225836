import styled from 'styled-components'

export const GetRegimeQuestionsContainer = styled.div`
  width: 100%;
  background-color: var(--brand-primary-white-color);
  padding: 10px;
  border-radius: 20px;
  margin: 0px 12px;
  max-width: 500px;

  .tabs-container {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;

    * + * {
      margin-left: 6px;
    }
  }

  .tab {
    font-size: 14px;
    font-weight: var(--brand-font-weight-black);
    color: var(--brand-primary-grey-color);
    line-height: 1;
    padding: 10px 0;
    background-color: var(--category-primary-white-color);
    border-radius: 12px;
    width: 100%;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--brand-primary-silver-color);
    cursor: pointer;

    &[data-completed='true'] {
      background-color: var(--category-primary-dark-color);
      color: var(--brand-primary-white-color);
    }

    &[data-active='true'] {
      background-color: var(--category-primary-color);
      color: var(--brand-primary-white-color);
    }
  }

  .check-icon {
    margin-left: 8px;
  }

  .question-container {
    padding: 10px;
  }

  .question {
    font-size: 20px;
    font-weight: var(--brand-font-weight-black);
    margin: 0px 0px 6px 0px;
  }

  .hint {
    font-size: 14px;
    font-weight: var(--brand-font-weight---brand-font-weight-normal);
    color: var(--brand-primary-grey-color);
    margin: 0px;
    margin-bottom: 12px;
  }

  .options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    margin-bottom: 16px;
  }

  .option {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--brand-primary-silver-color);
    border-radius: 12px;
    color: var(--brand-primary-grey-color);

    &[data-selected='true'] {
      border-color: var(--category-primary-color);
      color: var(--category-primary-color);
    }
  }

  .next-button {
    font-size: 14px;
    font-weight: var(--brand-font-weight-black);
    color: var(--brand-primary-white-color);
    background-color: var(--brand-primary-black-color);
    width: 100%;
    font-family: var(brand---brand-font-family-text);
    outline: 0px;
    border: 0px;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 12px;

    :disabled {
      cursor: not-allowed;
    }
  }

  .reset-button {
    font-size: 14px;
    font-weight: var(--brand-font-weight-black);
    color: var(--category-primary-color);
    outline: 0px;
    border: 0px;
    width: 100%;
    line-height: 1;
    background: transparent;
    :disabled {
      cursor: not-allowed;
    }
  }

  .reset-button:focus,
  .reset-button:active,
  .reset-button:target,
  .reset-button:visited {
    outline: 0;
    box-shadow: none !important;
  }
  @media screen and (min-width: 768px) {
    padding: 15px;

    .question-container {
      padding: 15px;
    }

    .tabs-container {
      margin-bottom: 24px;
    }

    .tab {
      font-size: 20px;
      padding: 16px 0px;
      border-radius: 20px;
    }

    .question {
      font-size: 28px;
      margin-bottom: 8px;
    }

    .hint {
      font-size: 16px;
      margin-bottom: 24px;
    }

    .options {
      gap: 10px;
      margin-bottom: 24px;
    }

    .option {
      font-size: 20px;
      padding: 10px 20px;
      border-width: 2px;
      border-radius: 20px;
      cursor: pointer;
    }

    .next-button {
      font-size: 20px;
      padding: 16px;
      border-radius: 20px;
      margin-bottom: 20px;
    }

    .reset-button {
      font-size: 20px;
    }
  }
`
