import {useState, useMemo, useCallback, useRef} from 'react'

function useGetRegimeQuestions({questions}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  //state to store answers of active tab temporarily
  const [temporaryAnswers, setTemporaryAnswers] = useState([])
  //state to store answers of all tabs together
  const [answers, setAnswers] = useState({})

  //refs for storing analytics data
  const questionsRef = useRef([])
  const answersRef = useRef([])

  const activeTabData = useMemo(
    () => questions[activeTabIndex],
    [activeTabIndex, questions]
  )

  const isButtonDisabled = useMemo(
    () => !temporaryAnswers.length,
    [temporaryAnswers]
  )

  const isTabActive = useCallback(
    (index) => {
      return activeTabIndex === index
    },
    [activeTabIndex]
  )

  const isOptionSelected = useCallback(
    (option) => {
      return !!temporaryAnswers.find((item) => option === item)
    },
    [temporaryAnswers]
  )

  const handleOptionClicked = useCallback(
    (item) => {
      if (activeTabData?.multiSelect) {
        const itemPresent = temporaryAnswers.find((answer) => answer === item)

        if (itemPresent) {
          setTemporaryAnswers((data) =>
            data.filter((data) => data !== itemPresent)
          )
          return
        }

        setTemporaryAnswers((data) => [...data, item])
        return
      }

      setTemporaryAnswers([item])
    },
    [activeTabData?.multiSelect, temporaryAnswers]
  )

  return {
    state: {
      activeTabIndex,
      temporaryAnswers,
      answers,
      isButtonDisabled,
      isTabActive,
      isOptionSelected,
      activeTabData,
    },
    actions: {
      handleOptionClicked,
      setAnswers,
      setTemporaryAnswers,
      setActiveTabIndex,
    },
    questionsRef,
    answersRef,
  }
}

export {useGetRegimeQuestions}
