import styled from 'styled-components'

export const MobileCardWrapper = styled.div`
  width: 100%;
  height: 163px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    97.82deg,
    rgba(102, 80, 154, 0.06) 0%,
    rgba(102, 80, 154, 0.26) 102.14%
  );
  border-radius: 16px;
  padding-top: 15px;
  padding-left: 20px;
  font-family: var(--brand-font-family);
  font-style: normal;
  font-weight: var(--brand-font-weight-normal);
  overflow: hidden;

  .card-left-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 1;
  }

  .card-heading {
    font-weight: var(--brand-font-weight-heavy);
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    color: var(--category-primary-dark-color);
    margin-bottom: 16px;
  }

  .card-benefit-container {
    display: flex;
    align-items: center;

    :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .card-benefit-img-container {
    width: 15px;
    height: 15px;
    margin-right: 9px;
  }

  .card-benefit-img {
    height: auto;
    width: 100%;
    aspect-ratio: auto 1;
    object-fit: cover;
  }

  .card-benefit-text-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    color: var(--category-primary-color);
  }

  .card-benefit-text {
    span {
      font-weight: var(--brand-font-weight-black);
    }
  }

  .card-right-section {
    height: 100%;
  }

  .card-image {
    width: auto;
    height: 100%;
    aspect-ratio: auto 108/30;
    object-fit: cover;
  }
`
