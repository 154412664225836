import styled from 'styled-components'

export const GetRegimeCardContainer = styled.div`
  width: 100%;
  max-width: 1124px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--category-primary-bg1-color);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .background-image {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
  }

  .content-left {
    display: flex;
    position: relative;
    left: -30px;
    align-self: flex-end;
    justify-content: flex-start;
  }

  .product-image {
    width: 120px;
    height: auto;
    object-fit: cover;
    position: relative;
    bottom: 0;
  }

  .content {
    padding: 16px 0px;
  }

  .title {
    font-size: 18px;
    font-weight: var(--brand-font-weight-heavy);
    color: var(--category-primary-dark-color);
    margin: 0px 0px 8px 0px;
    line-height: 1;
  }

  .description {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    color: var(--category-primary-color);
    margin: 0;
    line-height: 20px;
    width: 110%;
  }

  .arrow-image {
    height: 36px;
    width: 36px;
    object-fit: cover;
    margin-right: 10px;
  }

  @media screen and (min-width: 768px) {
    border-radius: 36px;

    .title {
      font-size: 36px;
      margin-bottom: 16px;
    }

    .content {
      padding: 50px 0px;
    }

    .description {
      font-size: 28px;
      line-height: 46px;
      width: 100%;
    }

    .arrow-image {
      margin-right: 70px;
    }

    .product-image {
      width: 350px;
      padding: 20px 20px 0px 20px;
    }

    .content-left {
      left: 0px;
      margin-left: 60px;
    }

    .arrow-image {
      width: 60px;
      height: 60px;
    }
  }
`
