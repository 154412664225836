import styled from 'styled-components'

export const StyledBlogListingCardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 17px;

  .img-container {
    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient( 7.62deg,rgba(0,0,0,0) 5.08%,rgba(0,0,0,0.35) 95.23% ),linear-gradient(0deg,rgba(22,18,30,0.1),rgba(22,18,30,0.1));
      -webkit-transform: matrix(-1,0,0,1,0,0);
      -ms-transform: matrix(-1,0,0,1,0,0);
      transform: matrix(-1,0,0,1,0,0);
    }

      .blog-listing-card__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 17px;
    aspect-ratio: 365/259;
  }
  }

  .blog-listing-card__bottom-data-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--brand-primary-white-color);
    border-radius: 17px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.56) 12.47%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .blog-listing-card__bottom-top-line {
    font-weight: var(--brand-font-weight-medium);
    font-size: 12px;
    line-height: 16px;
    opacity: 0.8;
    display: flex;
    gap: 10px;
    margin-bottom: 4px;
  }
  .blog-listing-card__bottom-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-shrink: 0;
    white-space: nowrap;
  }
  .blog-listing-card__title {
    font-weight: var(--brand-font-weight-heavy);
    font-size: 18px;
    line-height: 25px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .blog-listing-card__button {
    background: transparent;
    color: var(--brand-primary-white-color);
    border: 1px solid var(--brand-primary-white-color);
    font-weight: var(--brand-font-weight-black);
    font-size: 12px;
    line-height: 16px;
    border-radius: 15.5px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .blog-listing-card__blog-details {
    margin-right: 12px;
  }

  @media screen and (min-width: 768px) {
    border-radius: 22px;
    .blog-listing-card__bottom-top-line {
      font-size: 16px;
      line-height: 22px;
    }
    .blog-listing-card__title {
      font-size: 24px;
      line-height: 33px;
    }
    .blog-listing-card__button {
      font-weight: var(--brand-font-weight-medium);
      font-size: 16px;
      line-height: 22px;
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 21px;
    }
    .blog-listing-card__bottom-data-container {
      padding: 20px;
    }
  }
`
