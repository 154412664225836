import styled from 'styled-components'

export const ProcessStepsContainer = styled.div`
  max-width: 1124px;
  margin: auto auto 60px auto;
  width: 100%;
  color: var(--brand-font-default-color);

  .process-steps {
    max-width: 934px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }

  .step-container {
    display: flex;
    flex-grow: 1;
    overflow: visible;

    ::after {
      content: ' ';
      height: 2px;
      background-color: var(--category-primary-color);
      flex-grow: 1;
      display: inline-block;
      margin-top: 37px;
    }

    :last-child {
      flex-grow: 0;

      ::after {
        flex-grow: 0;
        display: none;
      }
    }
  }

  .step-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 74px;
  }

  .step-image {
    border-radius: 50%;
    margin-bottom: 22px;
    height: 74px;
    width: 74px;
    object-fit: cover;
    flex-shrink: 0;
    border: 2px solid var(-category-primary-bg2-color);
  }

  .step-label-container {
    position: relative;
    text-align: center;
    width: 80px;
  }

  .step-label {
    font-size: 14px;
    color: var(--category-primary-color);
    font-weight: var(--brand-font-weight-normal);
  }

  .step-number {
    font-size: 78px;
    color: var(--category-primary-color);
    opacity: 0.05;
    font-weight: var(--brand-font-weight-heavy);
    position: absolute;
    top: -12px;
    line-height: 1;
    left: 0px;
    right: 0px;
    letter-spacing: 0.8px;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;

    .process-steps {
      padding: 0px;
    }

    .step-container::after {
      margin-top: 70px;
      height: 3.5px;
    }

    .step-data {
      width: 140px;
    }

    .step-image {
      height: 140px;
      width: 140px;
      border: 2px solid var(--category-primary-bg2-color);
    }

    .step-label-container {
      width: 300px;
    }

    .step-label {
      font-size: 24px;
    }

    .step-number {
      font-size: 138px;
      top: -28px;
    }
  }
`
