import styled from 'styled-components'

export const MedicalTeamContainer = styled.div`
  max-width: 1124px;
  margin: auto auto 60px auto;
  padding: 0 16px;
  width: 100%;
  color: var(--brand-font-default-color);

  .title-container-medical-team {
    margin-bottom: 8px !important;
  }

  .title-with-description {
    > * {
      font-size: inherit;
      font-weight: inherit;
      padding: 0;
      margin: 0;
    }
  }

  .section-description {
    font-style: normal;
    margin-bottom: 20px;
    color: var(--brand-font-default-color);
  }

  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;
    padding: 0px;

    .title-container-medical-team {
      margin-bottom: 12px !important;
    }

    .title-with-description {
      > * {
        padding: 0;
        margin: 0;
        font-size: inherit;
        font-weight: inherit;
      }
    }
    .section-description {
      margin-bottom: 60px;
    }
  }
`
