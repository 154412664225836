import styled from 'styled-components'

export const GetStartedContainer = styled.div`
  width: 100%;
  margin: auto auto 60px auto;

  .filters-container {
    position: sticky;
    top: 50px;
    background-color: var(--brand-primary-silver-color);
    padding: 4px;
    z-index: 2;
  }
  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;
  }
`
