import styled from 'styled-components'

export const GetRegimeResultsContainer = styled.div`
  background-color: var(--brand-primary-white-color);
  width: 100%;
  height: 70%;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 20px;
  margin: 0px 16px;
  max-width: 750px;

  /* Update default browser scroll width and color */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border: 3px rgba(0, 0, 0, 0.2) solid;
    border-top: 3px rgba(255, 255, 255, 0) solid;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .top-section {
    padding: 20px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .selected-answers-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }

  .selected-answer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :not(:last-child) {
      border-right: 1px solid var(--brand-primary-silver-color);
    }
  }

  .label {
    font-size: 12px;
    color: var(--category-primary-color);
    font-weight: var(--brand-font-weight-normal);
    line-height: 1;
    margin-bottom: 4px;
  }

  .answer {
    font-size: 12px;
    font-weight: var(--brand-font-weight-black);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 120px;
  }

  .title {
    margin: 0;
    font-size: 16px;
    font-weight: var(black font --brand-font-weight-heavy);
  }

  .icons-container {
    display: flex;
    align-items: center;
    height: min-content;
  }

  .cart-button {
    padding: 0;
    height: 30px;
    width: 30px;
    outline: 0;
    border: 0;
    background-color: transparent;
    margin-right: 30px;
    position: relative;

    .cart-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .cart-badge {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: var(--brand-primary-yellow-color);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: var(--brand-font-family-text);
      font-weight: 500;
      position: absolute;
      top: -12px;
      right: -12px;
    }
  }

  .close-button {
    height: 24px;
    width: 24px;
    outline: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-primary-red-color);
    border-radius: 8px;
    color: var(--brand-primary-white-color);
  }

  .step-data {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .step-number {
    font-size: 14px;
    color: var(--brand-primary-white-color);
    background-color: var(--brand-primary-teal-color);
    padding: 6px 10px;
    border-radius: 0 20px 20px 0;
    margin-right: 10px;
  }

  .step-label {
    font-size: 18px;
    font-weight: var(--brand-font-weight-black);
  }

  .products-container {
    width: 100%;
    padding: 20px 0 40px 16px;
    margin-left: 16px;
    border-left: 1px dashed var(--brand-primary-teal-color);
  }

  .products {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding-right: 40px;

    > * + * {
      margin-left: 12px;
    }
  }

  /* product card over-rides */
  .main-container {
    margin: 0 !important;
  }

  .product-card {
    width: auto !important;

    .label {
      display: none !important;
    }
  }

  .atc-btn {
    margin-top: 14px;
  }
  /* over-rides end */

  @media screen and (min-width: 768px) {
    height: 70vh;

    .title-container {
      margin-bottom: 22px;
    }

    .step-number {
      font-size: 18px;
      padding: 6px 16px;
      margin-right: 20px;
    }

    .top-section {
      padding: 40px;
    }

    .title {
      font-size: 28px;
    }

    .step-label {
      font-size: 40px;
    }

    .products-container {
      border-width: 1.5px;
    }

    .products-container {
      padding: 20px 0 40px 24px;
      margin-left: 24px;
    }

    .products {
      > * + * {
        margin-left: 24px;
      }
    }

    .selected-answers-container {
      padding: 16px;
    }

    .label {
      font-size: 16px;
    }

    .answer {
      font-size: 20px;
      max-width: 180px;
    }
  }
`
export const LoadingContainer = styled.div`
  padding: 20px;
`
