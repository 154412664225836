import isEmpty from 'lodash/isEmpty'
import React, {useMemo} from 'react'

import Responsive from '../../../ComponentsV2/HOC/Responsive'
import {Section} from '../../../ComponentsV2/common'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {FeelingConfusedContainer} from './FeelingConfused.styles'
import FeelingConfusedDesktop from './FeelingConfusedDesktop/FeelingConfusedDesktop'
import FeelingConfusedMobile from './FeelingConfusedMobile/FeelingConfusedMobile'

const FeelingConfused = (props) => {
  const {
    isMobile = false,
    state = {},
    actions = {},
    inFirstFold = false,
  } = props || {}

  const {
    title,
    subtitle,
    sectionName = 'feelingConfused',
    themeToUse,
    forPage = 'homepage',
  } = state

  const headingClassName = useMemo(() => {
    if (forPage === 'homepage') return 'homepage-feeling-confused-title'
    return ''
  }, [])

  if (isEmpty(state)) {
    return null
  }

  return (
    <FeelingConfusedContainer
      data-section={sectionName}
      data-category={themeToUse}
      data-forpage={forPage}
      data-scrollsection="feelingConfused"
    >
      <Section state={{title, subtitle, headingClassName}} />
      {isMobile ? (
        <FeelingConfusedMobile
          state={state}
          actions={actions}
          inFirstFold={inFirstFold}
        />
      ) : (
        <FeelingConfusedDesktop
          state={state}
          actions={actions}
          inFirstFold={inFirstFold}
        />
      )}
    </FeelingConfusedContainer>
  )
}
export default withCustomErrorBoundary(
  Responsive(FeelingConfused),
  'FeelingConfused'
)
