import React, {useMemo} from 'react'
import noop from 'lodash/noop'

import {MedicalTeamCard} from './MedicalTeamCard'
import {MedicalTeamContainer} from './MedicalTeam.styles'
import {CustomSlider, Section} from '../../../ComponentsV2/common'
import isEmpty from 'lodash/isEmpty'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import Typography from '../../../ComponentsV2/common/Typography/Typography'

function MedicalTeam(props) {
  const {state = {}, actions = {}} = props || {}

  const {
    title = '',
    description = '',
    team = [],
    sectionName = 'medicalTeam',
    descriptionBgColor = '',
    themeToUse = '',
  } = state || {}

  const {onMedicalCardCtaClick = noop} = actions

  const sliderSettings = useMemo(() => {
    return {
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1.6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
          },
        },
      ],
    }
  }, [])

  const hasDescription = !!description

  const sectionState = useMemo(() => {
    return {
      title: title,
      customClassName: hasDescription ? 'title-container-medical-team ' : '',
      headingClassName: hasDescription ? 'title-with-description' : '',
    }
  }, [title, hasDescription])

  if (isEmpty(team)) {
    return null
  }

  return (
    <MedicalTeamContainer data-section={sectionName}>
      <Section state={sectionState} />
      {!!hasDescription && (
        <div className="section-description">
          <Typography text={description} variant="body-base-regular" />
        </div>
      )}

      <CustomSlider sliderSettings={sliderSettings}>
        {team.map((member) => (
          <MedicalTeamCard
            key={member.name}
            state={member}
            actions={{onButtonClick: onMedicalCardCtaClick}}
            descriptionBgColor={descriptionBgColor}
            themeToUse={themeToUse}
          />
        ))}
      </CustomSlider>
    </MedicalTeamContainer>
  )
}

export default withCustomErrorBoundary(MedicalTeam, 'MedicalTeam')
