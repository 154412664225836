import isEmpty from 'lodash/isEmpty'
import React from 'react'

import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {DesktopCardWrapper} from './styles'

function DesktopCard({cardData = {}}) {
  const {image = '', title = '', subtext = ''} = cardData
  if (isEmpty(cardData)) return null
  return (
    <DesktopCardWrapper>
      <div className="card-left-container">
        <div className="card-img-container">
          <OptimizedImage
            source={image}
            aspectWidth="65"
            aspectHeight="65"
            desktopViewWidth="THIRD"
            mobileViewWidth="THIRD"
            altText="benefit"
            loading="lazy"
            customClassName="card-img"
          />
        </div>
      </div>
      <div className="card-right-container">
        <label className="card-title">{title}</label>
        <label className="card-subtext">{subtext}</label>
      </div>
    </DesktopCardWrapper>
  )
}

export default DesktopCard
