import React, {Children, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import {BenefitCardsWrapper} from './BenefitCards.styles'
import MobileCard from './MobileCard'
import DesktopCard from './DesktopCard'
import {Section} from '../../../ComponentsV2/common'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'

function BenefitCards(props) {
  const {
    desktopData = {},
    mobileData = {},
    sectionName = 'benefits',
  } = props.state || {}
  const {isMobile = false} = props

  const {heading = ''} = desktopData

  const benefitCards = useMemo(() => {
    if (isMobile) {
      const {cards = []} = mobileData || {}
      return Children.toArray(
        cards?.map((card) => <MobileCard cardData={card} />)
      )
    }
    const {cards = []} = desktopData || {}
    return (
      <div className="benefits-desktop-card-container">
        {Children.toArray(
          cards?.map((card) => <DesktopCard cardData={card} />)
        )}
      </div>
    )
  }, [mobileData, desktopData, isMobile])

  if (isEmpty(desktopData) || isEmpty(mobileData)) return null

  return (
    <BenefitCardsWrapper data-section={sectionName} className="benefit-cards">
      {!isMobile ? <Section state={{title: heading}} /> : null}
      {benefitCards}
    </BenefitCardsWrapper>
  )
}

export default withCustomErrorBoundary(
  React.memo(Responsive(BenefitCards)),
  'BenefitCards'
)
