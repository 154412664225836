import styled from 'styled-components'

export const TestimonialsContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;

  .content {
    padding-bottom: 16px !important;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 120px;
  }
`
