import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useCallback} from 'react'

import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import Typography from '../../../../ComponentsV2/common/Typography/Typography'
import {MedicalTeamCardContainer} from './MedicalTeamCardComponent.styles'

const MedicalTeamCard = (props) => {
  const {
    image = '',
    altText = '',
    name = '',
    designation = '',
    description = '',
    buttonText = '',
    buttonLink = '',
  } = props.state || {}
  const {themeToUse = '', descriptionBgColor = ''} = props

  const {onButtonClick = noop} = props.actions || {}

  const handleOnButtonClick = useCallback(
    () => onButtonClick(buttonLink),
    [onButtonClick, buttonLink]
  )

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <MedicalTeamCardContainer
      descriptionBgColor={descriptionBgColor}
      data-category={themeToUse}
    >
      <div className="medical-team-card__card-container">
        <OptimizedImage
          source={image}
          aspectWidth="40%"
          aspectHeight="auto"
          desktopViewWidth="HALF"
          mobileViewWidth="HALF"
          altText={altText}
          loading="lazy"
          className="medical-team-card__image"
        />
        <div className="medical-team-card__data-container">
          <Typography
            text={name}
            variant="subheading-bold"
            customClassName="medical-team-card__tilte"
          />
          <div className="medical-team-card__subtitle">{designation}</div>
          <div
            className="medical-team-card__description"
            dangerouslySetInnerHTML={{__html: description}}
          ></div>
          {buttonText && (
            <div className="medical-team-card__button-container">
              <button
                className="medical-team-card__button"
                onClick={handleOnButtonClick}
              >
                {buttonText} <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}
        </div>
      </div>
    </MedicalTeamCardContainer>
  )
}
export default MedicalTeamCard
