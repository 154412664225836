import styled from 'styled-components'

export const StyledHeroBannerWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 30px;

  .hero-banner__image {
    display: block;
    width: 100%;
    height: auto;
  }
  .hero-banner__title-container {
    position: absolute;
    bottom: 0;
    top: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    z-index: 1;
  }
  .hero-banner__title {
    font-weight: var(--brand-font-weight-heavy);
    font-size: 32px;
    line-height: 1;
    margin-bottom: 14px;
    padding: 0 16px;
  }
  .hero-banner__subtitle {
    font-size: 18px;
    line-height: 1;
    font-weight: var(--brand-font-weight-normal);
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 60px;

    .hero-banner__title-container {
      width: auto;
      justify-content: flex-start;
      color: var(--category-primary-color);
      text-align: left;
      top: 30%;
      left: 10%;
    }
    .hero-banner__title {
      font-size: 3.5vmax;
      line-height: 1;
      letter-spacing: -1px;
      margin-bottom: 16px;
      padding: 0;
    }
    .hero-banner__subtitle {
      font-size: 2.4vmax;
      line-height: 1;
    }
  }
`
