import React from 'react'

import {withCustomErrorBoundary} from '../../utils/withCustomErrorBoundary'
import {Benefits} from './BenefitCards'
import {BestSellers} from './BestSellers'
import {BlogListing} from './BlogListing'
import {CategoryPageContainer} from './CategoryPage.styles'
import {ChooseYourConcern} from './ChooseYourConcern'
import {FeelingConfused} from './FeelingConfused'
import {GetRegime} from './GetRegime'
import {GetStarted} from './GetStarted'
import {HeroBanner} from './HeroBanner'
import {MedicalTeam} from './MedicalTeam'
import {ProcessSteps} from './ProcessSteps'
import {SafeAndEffectiveIngredients} from './SafeAndEffectiveIngredients'
import {Testimonials} from './Testimonials'
import {VideoReviews} from './VideoReviews'

function CategoryPage(props) {
  const {banner, order, sections, category = ''} = props || {}
  const {
    onViewProductClick,
    onMedicalCardCtaClick,
    onTagClick,
    onViewAllClick,
  } = props.actions || {}

  const {
    processSteps,
    chooseYourConcern,
    testimonial,
    medicalTeam,
    blogListing,
    feelingConfused,
    benefits,
    bestSellers,
    getStarted,
    ingredients,
    getRegime,
    videoReviews,
  } = sections || {}

  return (
    <CategoryPageContainer data-category={category} order={order}>
      <HeroBanner state={banner} />
      <ProcessSteps state={processSteps} />
      <ChooseYourConcern state={chooseYourConcern} />
      <Testimonials state={testimonial} />
      <MedicalTeam state={medicalTeam} actions={{onMedicalCardCtaClick}} />
      <BlogListing state={blogListing} />
      <FeelingConfused state={feelingConfused} actions={{onTagClick}} />
      <Benefits state={benefits} />
      <BestSellers state={bestSellers} actions={{onViewAllClick}} />
      <GetStarted state={getStarted} actions={{onViewAllClick}} />
      <SafeAndEffectiveIngredients
        state={ingredients}
        actions={{onViewProductClick}}
      />
      <GetRegime state={getRegime} />
      <VideoReviews state={videoReviews} />
    </CategoryPageContainer>
  )
}

export default withCustomErrorBoundary(CategoryPage, 'CategoryPage')
