import styled from 'styled-components'

export const MedicalTeamCardContainer = styled.div`
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  height: 380px;

  .medical-team-card__card-container {
    display: flex;
    width: 100%;
  }
  .medical-team-card__image {
    width: 40%;
    object-fit: cover;
    height: auto;
  }
  .medical-team-card__data-container {
    width: 60%;
    background: var(--category-primary-bg1-color);
    padding: 10px 14px;

    ${(props) =>
      !!props.descriptionBgColor &&
      `
      background:${props.descriptionBgColor}
    `}
  }
  .medical-team-card__tilte {
    color: var(--category-primary-color);
  }
  .medical-team-card__subtitle {
    font-weight: var(--brand-font-weight-medium);
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    color: var(--category-primary-color);
  }
  .medical-team-card__description {
    font-weight: var(--brand-font-weight-normal);
    font-size: 14px;
    line-height: 20px;
    color: var(--brand-primary-grey-color);
  }
  .color-primary {
    color: var(--category-primary-color);
  }
  .medical-team-card__button {
    background: var(--brand-primary-white-color);
    border-radius: 20px;
    font-weight: var(--brand-font-weight-black);
    font-size: 14px;
    line-height: 19px;
    color: var(--category-primary-color);
    border: none;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    margin-bottom: 16px;

    .fa-chevron-right {
      margin-left: 6px;
    }
  }

  @media screen and (min-width: 768px) {
    border-radius: 24px;
    height: 700px;

    .medical-team-card__data-container {
      padding: 30px;
      width: 60%;
    }
    .medical-team-card__subtitle {
      font-weight: var(--brand-font-weight-medium);
      font-size: 16px;
      line-height: 22px;
    }
    .medical-team-card__description {
      font-weight: var(--brand-font-weight-normal);
      font-size: 24px;
      line-height: 33px;
      padding-right: 40px;
    }
    .medical-team-card__button {
      font-weight: var(--brand-font-weight-black);
      font-size: 21.7568px;
      line-height: 30px;
      padding: 8px 19px;
    }
  }
`
