import React, {useMemo} from 'react'
import noop from 'lodash/noop'

import {GetStartedContainer} from './GetStarted.styles'
import {SectionWithOffset, Section} from '../../../ComponentsV2/common'
import {ProductListing} from '../ProductListing'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import {ViewAllButton} from '../ViewAllButton'
import ProductListingContext from '../../../ComponentsV2/Context/ProductListingContext'
import isEmpty from 'lodash/isEmpty'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {FiltersV2} from '../../../ComponentsV2/common/FiltersV2'

function GetStarted(props) {
  const {state = {}, actions = {}, isMobile = false} = props || {}

  const {
    title = '',
    subtitle = '',
    products = [],
    viewAllLink = '',
    sectionName = 'getStarted',
    productListingConfig = {},
    filteredProductsState,
    filters = [],
  } = state
  const {onViewAllClick = noop, onFilterApply} = actions

  const productListingState = useMemo(() => {
    return {
      products,
      productListingConfig,
      filteredProductsState,
      sectionName,
    }
  }, [products, productListingConfig, filteredProductsState, sectionName])

  if (isEmpty(products)) {
    return null
  }

  return (
    <ProductListingContext.Provider value={productListingConfig}>
      <GetStartedContainer data-section={sectionName}>
        <SectionWithOffset>
          <Section
            state={{title, subtitle, viewAllLink}}
            actions={{onViewAllClick}}
          >
            {filters.length > 0 && (
              <div className="filters-container">
                <FiltersV2
                  filters={filters}
                  onFilterApply={(key, newFilters) =>
                    onFilterApply(key, newFilters, sectionName)
                  }
                />
              </div>
            )}
            <ProductListing state={productListingState} actions={actions} />
            {isMobile && (
              <ViewAllButton state={{viewAllLink}} actions={{onViewAllClick}} />
            )}
          </Section>
        </SectionWithOffset>
      </GetStartedContainer>
    </ProductListingContext.Provider>
  )
}

export default Responsive(withCustomErrorBoundary(GetStarted, 'GetStarted'))
