import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import noop from 'lodash/noop'

import {StyledChooseYourConcerWrapper} from './ChooseYourConcern.styles'
import {Section} from '../../../ComponentsV2/common/Section'
import isEmpty from 'lodash/isEmpty'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import OptimizedImage from '../../../ComponentsV2/common/OptimizedImage/OptimizedImage'
import Typography from '../../../ComponentsV2/common/Typography/Typography'
import Responsive from '../../../ComponentsV2/HOC/Responsive'

const ChooseYourConcern = (props) => {
  const {
    concerns = [],
    headingLevel = 2,
    headingsFor = {},
    title = '',
    subtitle = '',
    sectionName = 'chooseYourConcern',
  } = props.state || {}

  const {cards: cardsHeadingLevel = 3} = headingsFor || {}
  const {onConcernClick = noop} = props.actions || {}

  if (isEmpty(concerns)) {
    return null
  }

  return (
    <StyledChooseYourConcerWrapper data-section={sectionName}>
      <Section state={{title, subtitle, headingLevel}} />
      <div className="choose-you-concern__grid">
        {concerns.map((concern) => {
          const {
            link = '',
            title = '',
            subtitle = '',
            image = '',
            altText = '',
            redirectionData = {},
          } = concern || {}
          return (
            <div key={title} className="choose-your-concern__card-container">
              <div
                className="choose-your-concern__card"
                onClick={() =>
                  onConcernClick({link, type: title, redirectionData})
                }
              >
                <OptimizedImage
                  source={image}
                  altText={altText}
                  customClassName="choose-your-concern__image"
                  desktopViewWidth="HALF"
                  mobileViewWidth="HALF"
                />
                <div className="choose-your-concern__heading">
                  <div className="choose-your-concern__title">
                    <Typography
                      text={title}
                      variant="body-base-bold"
                      customClassName="choose-your-concern__title-text"
                      headingLevel={cardsHeadingLevel}
                    />
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                  <div className="choose-your-concern__subtitle">
                    <Typography text={subtitle} variant="body-base-regular" />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </StyledChooseYourConcerWrapper>
  )
}
export default withCustomErrorBoundary(ChooseYourConcern, 'ChooseYourConcern')
