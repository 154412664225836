import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .video_container {
    width: min(
      980px,
      calc(300px + (980 - 300) * ((100vw - 360px) / (1440 - 360)))
    );
    margin: auto;
    height: min(
      490px,
      calc(161px + (490 - 161) * ((100vw - 360px) / (1440 - 360)))
    );
  }

  &.center {
    transform: scale(1.7);
  }
`
