import React, {useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'

import {StyledBlogListingWrapper} from './BlogListing.styles'
import {Section} from '../../../ComponentsV2/common/Section'
import {CustomSlider} from '../../../ComponentsV2/common/CustomSlider'
import BlogListingCard from './BlogListingCard/BlogListingCard'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'

const BlogListing = (props) => {
  const {state = {}} = props || {}

  const {
    title = '',
    subtitle = '',
    blogs = [],
    sectionName = 'blogListing',
  } = state

  const sliderSettings = useMemo(() => {
    return {
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    }
  }, [])

  if (isEmpty(blogs)) {
    return null
  }

  return (
    <StyledBlogListingWrapper data-section={sectionName}>
      <Section state={{title, subtitle}} />
      <CustomSlider sliderSettings={sliderSettings}>
        {blogs.map((blog) => (
          <BlogListingCard state={blog} key={blog?.name} />
        ))}
      </CustomSlider>
    </StyledBlogListingWrapper>
  )
}
export default withCustomErrorBoundary(BlogListing, 'BlogListing')
