import styled from 'styled-components'

export const CartPageComponentWrapper = styled.div`
  background-color: var(--brand-primary-silver-color);
  background-color: var(--brand-primary-white-color);
`

export const CartPageComponentContent = styled.div`
  width: 100vw;
  padding-bottom: 20px;

  .padding-x-12 {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .apply-discount-card {
    padding-top: 12px;
  }

  .view-prescription-card-container {
    width: 100%;
  }

  .apply-discount {
    padding-top: 12px;
  }

  .bill-details-wrapper {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .drawer-content {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 80px;
  }

  .cart-items-wrapper {
    margin-bottom: 5px;

    .line-item-wrap {
      width: 100%;
      border: none;

      &:not(:last-child) {
        border-bottom: 1px solid #ededed;
      }
    }
    .line-item-wrap.freeGift {
      border-top: 1px solid #ededed;
    }
  }

  @media screen and (min-width: 768px) {
    width: 400px;
    margin: 0px auto 32px auto;
  }

  .top-arrow--righticon {
    display: none;
  }

  .top-arrow--righticon ~ p {
    display: none;
  }
`
export const EmptyCartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledLoader = styled.div`
  margin-bottom: 16px;

  .loading-card {
    width: 100%;
    height: ${(props) => props.cardHeight}px;
    border-radius: 16px;
    margin-bottom: 12px;
  }
  .shimmer {
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--category-primary-color);
  .category-label {
    margin-right: 10px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
  }

  .dash {
    flex: 1;
    height: 1px;
    border-bottom: 2px dashed var(--category-primary-color);
  }

  @media (min-width: 768px) {
    .category-label {
      font-size: 18px;
      line-height: 18px;
    }
  }
`

export const DeliveryToastContainer = styled.div`
  display: flex;
  width: 100%;
  padding: var(--dls-size-16);
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--dls-divider-light-color);
  border-radius: var(--dls-size-16);
  margin-bottom: var(--dls-size-8);
  margin-top: var(--dls-size-8);
  .message {
    display: flex;
  }
  .green {
    color: var(--dls-brand-primary-color-500);
    margin-left: var(--dls-size-4);
  }
  .bold {
    font-weight: var(--brand-font-weight-black);
    margin-left: var(--dls-size-4);
  }
`
