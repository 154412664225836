import isEmpty from 'lodash/isEmpty'
import React from 'react'

import HeadingComponent from '../../../../ComponentsV2/common/HeadingComponent'
import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {StyledBlogListingCardWrapper} from './BlogListingCard.styles'

const BlogListingCard = (props) => {
  const {
    headingLevel = 2,
    image = '',
    altText = '',
    topic = '',
    readTime = '',
    title = '',
    button = '',
    link = '',
  } = props.state || {}

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <StyledBlogListingCardWrapper>
      <div className="img-container">
        <OptimizedImage
          source={image}
          aspectWidth="211"
          aspectHeight="297"
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          alt={altText}
          loading="lazy"
          customClassName="blog-listing-card__image"
        />
        <div className="img-overlay"></div>
      </div>
      <div className="blog-listing-card__bottom-data-container">
        <div className="blog-listing-card__blog-details">
          <div className="blog-listing-card__bottom-top-line">
            <div>{topic}</div>
            <div>{readTime}</div>
          </div>

          <HeadingComponent
            headingLevel={headingLevel}
            customClassName="blog-listing-card__title"
            label={title}
          />
        </div>

        <div className="blog-listing-card__bottom-line">
          <a className="blog-listing-card__button" href={link} target="__blank">
            {button}
          </a>
        </div>
      </div>
    </StyledBlogListingCardWrapper>
  )
}
export default BlogListingCard
