import styled from 'styled-components'

export const SafeAndEffectiveIngredientCardContainer = styled.div`
  border-radius: 16px;
  overflow: hidden;
  background: linear-gradient(
    295.17deg,
    var(--category-primary-color) 13.85%,
    rgba(var(--category-primary-color-value), 0.8) 100%
  );
  color: var(--brand-primary-white-color);

  .ingredient__heading-container {
    padding: 16px 20px;
  }
  .ingredient__heading {
    font-weight: var(--brand-font-weight-heavy);
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 10px;
  }
  .ingredient__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    opacity: 0.8;
    font-weight: var(--brand-font-weight-medium);
    font-size: 14px;
    line-height: 20px;
    height: 80px;
  }
  .ingredient__show-more {
    font-weight: var(--brand-font-weight-heavy);
    opacity: 1;
    position: relative;
    left: 4px;
    cursor: pointer;
    text-decoration: underline;
  }
  .ingredient__in-product {
    background: var(--category-primary-bg1-color);
    display: flex;
    justify-content: space-between;
  }
  .ingredient__in-product-data-container {
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 15px;
  }
  .ingredient__in-product-heading {
    font-weight: var(--brand-font-weight-medium);
    font-size: 12px;
    line-height: 16px;
    color: var(--brand-primary-grey-color);
    padding-bottom: 6px;
  }
  .ingredient__in-product-name {
    display: -webkit-box;
    font-weight: var(--brand-font-weight-heavy);
    font-size: 14px;
    line-height: 19px;
    color: var(--brand-primary-black-color);
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    height: 38px;
    -webkit-box-orient: vertical;
  }
  .ingredient__in-product-button {
    font-weight: var(--brand-font-weight-medium);
    font-size: 12px;
    line-height: 16px;
    background: var(--brand-primary-white-color);
    color: var(--category-primary-color);
    border: none;
    border-radius: 20px;
    position: relative;
    padding: 7px 11px;
  }
  .ingredient__in-product-button-text {
    padding-right: 10px;
  }
  .ingredient__in-product-image-container {
    display: flex;
    align-items: end;
  }
  .ingredient__in-product-image {
    height: 110px;
  }
  @media screen and (min-width: 768px) {
    border-radius: 28px;
    .ingredient__heading-container {
      padding: 20px 30px;
    }
    .ingredient__heading {
      font-size: 20px;
      line-height: 27px;
      padding-bottom: 15px;
    }
    .ingredient__description {
      font-size: 18px;
      line-height: 26px;
      height: 105px;
    }
    .ingredient__in-product-heading {
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 10px;
    }
    .ingredient__in-product-name {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 15px;
      height: 50px;
    }
    .ingredient__in-product-button {
      border-radius: 26px;
      font-size: 16px;
      line-height: 22px;
      padding: 9px 12px;
    }
    .ingredient__in-product-image {
      height: 144px;
    }
  }
`
