import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useCallback, useMemo} from 'react'

import LoadingState from '../../../../Components/Sections/CartPageComponent/LoadingState'
import Responsive from '../../../../ComponentsV2/HOC/Responsive'
import Card from '../../../../ComponentsV2/Screens/AllProductsV2/Products/Card'
import {ModalBackground} from '../../../../ComponentsV2/common'
import HeadingComponent from '../../../../ComponentsV2/common/HeadingComponent'
import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {StyledProductListingWrapper} from '../../ProductListing/ProductListing.styles'
import {EmptyResults} from '../EmptyResults'
import {
  GetRegimeResultsContainer,
  LoadingContainer,
} from './GetRegimeResults.styles'

function Loading() {
  return (
    <LoadingContainer>
      <LoadingState cardHeight={30} numberOfCards={1} />
      <LoadingState cardHeight={50} numberOfCards={1} />
      <LoadingState cardHeight={120} numberOfCards={3} />
    </LoadingContainer>
  )
}

function GetRegimeResults(props) {
  const {state = {}, actions = {}, isMobile = false} = props || {}
  const {
    regime: regimeData = {},
    headingLevel = 2,
    title = 'Find the right solutions',
    cartIcon = 'https://i.mscwlns.co/mosaic-wellness/image/upload/f_auto/v1648215965/Little%20Joys/HeaderFooter/Bag.png',
    isLoading = false,
    selectedAnswers = {},
    totalItems = 0,
    regimeFallback = '',
    sectionName = '',
  } = state
  const {
    onAddToCart = noop,
    onCloseClick = noop,
    onCartClick = noop,
    onProductClick = noop,
  } = actions

  const {regime = {}} = regimeData || {}

  const steps = useMemo(() => {
    if (regime) {
      return Object.keys(regime)
    }

    return []
  }, [regime])

  const isRegimeEmpty = useMemo(() => isEmpty(regime), [regime])

  const selectedAnswersLabels = useMemo(() => {
    if (isEmpty(selectedAnswers)) {
      return []
    }

    return Object.keys(selectedAnswers)
  }, [selectedAnswers])

  const handleContainerClicked = useCallback((e) => e.stopPropagation(), [])

  return (
    <ModalBackground dismissHandler={onCloseClick}>
      <GetRegimeResultsContainer onClick={handleContainerClicked}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="top-section">
              <div className="title-container">
                <HeadingComponent
                  headingLevel={headingLevel}
                  customClassName="title"
                  label={title}
                />
                <div className="icons-container">
                  <button onClick={onCartClick} className="cart-button">
                    <OptimizedImage
                      source={cartIcon}
                      desktopViewWidth="SMALL"
                      mobileViewWidth="SMALL"
                      alt="cart icon"
                      loading="lazy"
                      customClassName="cart-icon"
                    />
                    {totalItems !== 0 && (
                      <span className="cart-badge">{totalItems}</span>
                    )}
                  </button>
                  <button onClick={onCloseClick} className="close-button">
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
              <div className="selected-answers-container">
                {selectedAnswersLabels.map((label) => (
                  <div className="selected-answer">
                    <span className="label">{label}</span>
                    <span className="answer">{selectedAnswers[label][0]}</span>
                  </div>
                ))}
              </div>
            </div>

            {isRegimeEmpty ? (
              <EmptyResults state={{regimeFallback}} />
            ) : (
              <>
                {steps.map((step, index) => (
                  <div>
                    <div className="step-data">
                      <span className="step-number">Step {index + 1}</span>
                      <span className="step-label">{step}</span>
                    </div>
                    <div className="products-container">
                      <div className="products">
                        {regime[step]?.map((product) => (
                          <StyledProductListingWrapper className="product-card">
                            <Card
                              key={product.name}
                              isMobile={isMobile}
                              card={product}
                              onAddToCart={onAddToCart}
                              position={index + 1}
                              sectionName={sectionName}
                              onNavigation={onProductClick}
                            />
                          </StyledProductListingWrapper>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </GetRegimeResultsContainer>
    </ModalBackground>
  )
}

export default Responsive(GetRegimeResults)
