import styled from 'styled-components'

export const DesktopCardWrapper = styled.div`
  display: flex;
  padding: 30px;
  height: 169px;
  width: 49%;
  background: var(--category-primary-bg1-color);
  border-radius: 20px;
  font-family: var(--brand-font-family);
  font-style: normal;
  font-weight: var(--brand-font-weight-normal);

  .card-img-container {
    height: 65px;
    width: 65px;
    margin-right: 30px;
  }

  .card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .card-right-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  .card-title {
    font-weight: var(--brand-font-weight-black);
    font-size: 24px;
    line-height: 33px;
    color: var(--brand-primary-color);
  }

  .card-subtext {
    margin-top: 10px;
    font-weight: var(--brand-font-weight-light);
    font-size: 24px;
    line-height: 33px;
    color: var(--brand-primary-grey-color);
  }
`
