import isEmpty from 'lodash/isEmpty'
import React from 'react'

import HeadingComponent from '../../../../ComponentsV2/common/HeadingComponent'
import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {MobileCardWrapper} from './styles'

function MobileCard({cardData = {}}) {
  const {headingLevel = 2, image = '', title = '', benefits = []} = cardData

  if (isEmpty(cardData)) return null
  return (
    <MobileCardWrapper>
      <div className="card-left-section">
        <HeadingComponent
          headingLevel={headingLevel}
          customClassName="card-heading"
          label={title}
        />
        {benefits.map((benefit) => {
          const {image = '', text = ''} = benefit || {}
          return (
            <div className="card-benefit-container">
              <div className="card-benefit-img-container">
                <OptimizedImage
                  source={image}
                  aspectWidth="15"
                  aspectHeight="15"
                  desktopViewWidth="TINY"
                  mobileViewWidth="TINY"
                  altText="benefit"
                  loading="lazy"
                  customClassName="card-benefit-img"
                />
              </div>
              <div className="card-benefit-text-container">
                <div
                  className="card-benefit-text"
                  dangerouslySetInnerHTML={{__html: text}}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className="card-right-section">
        <OptimizedImage
          source={image}
          aspectWidth="130"
          aspectHeight="108"
          desktopViewWidth="SMALL"
          mobileViewWidth="SMALL"
          altText="benefit-card"
          loading="lazy"
          customClassName="card-image"
        />
      </div>
    </MobileCardWrapper>
  )
}

export default MobileCard
