import styled from 'styled-components'

export const GetRegimeContainer = styled.div`
  max-width: 1124px;
  margin: auto auto 10px auto;
  padding: 0px 16px;

  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;
    padding: 0;
  }
`
