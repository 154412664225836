import React, {useMemo} from 'react'

import {
  SectionWithOffset,
  Section,
  CustomSlider,
} from '../../../ComponentsV2/common'
import {TestimonialsContainer} from './Testimonials.styles'
import {TestimonialCard} from './TestimonialCard'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import isEmpty from 'lodash/isEmpty'

function Testimonials(props) {
  const {state = {}, isMobile = false} = props || {}

  const {
    title = '',
    subtitle = '',
    testimonials = [],
    backgroundImageDesktop = '',
    backgroundImageMobile = '',
    alt = '',
    sectionName = 'testimonial',
  } = state || {}

  const sliderSettings = useMemo(() => {
    return {
      slidesToShow: isMobile ? 1 : 1.5,
      slidesToScroll: 1,
      dots: isMobile ? true : false,
    }
  }, [isMobile])

  if (isEmpty(testimonials)) {
    return null
  }

  return (
    <TestimonialsContainer data-section={sectionName}>
      <SectionWithOffset
        state={{backgroundImageDesktop, backgroundImageMobile, alt}}
      >
        <Section state={{title, subtitle}} />
        <CustomSlider sliderSettings={sliderSettings}>
          {testimonials.map((card) => (
            <TestimonialCard key={card?.userName} state={card} />
          ))}
        </CustomSlider>
      </SectionWithOffset>
    </TestimonialsContainer>
  )
}

export default Responsive(withCustomErrorBoundary(Testimonials, 'Testimonials'))
