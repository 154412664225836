import React, {useCallback} from 'react'
import noop from 'lodash/noop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

import {GetRegimeQuestionsContainer} from './GetRegimeQuestions.styles'
import {ModalBackground} from '../../../../ComponentsV2/common/ModalBackground'
import {useGetRegimeQuestions} from './useGetRegimeQuestions'

function GetRegimeQuestions(props) {
  const {state = {}, actions = {}} = props || {}
  const {questions = [], category = ''} = state
  const {
    onGetRegimeClick = noop,
    onClose = noop,
    toggleResultsModal = noop,
    updateAnswersData = noop,
    onFilterApplied = noop,
  } = actions

  const {
    state: {
      activeTabIndex,
      answers,
      temporaryAnswers,
      isButtonDisabled,
      isOptionSelected,
      isTabActive,
      activeTabData,
    },
    actions: {
      handleOptionClicked,
      setAnswers,
      setActiveTabIndex,
      setTemporaryAnswers,
    },
    questionsRef,
    answersRef,
  } = useGetRegimeQuestions({questions})

  const handleContainerClicked = useCallback((e) => e.stopPropagation(), [])

  const handleNextButtonClicked = useCallback(() => {
    if (activeTabIndex === questions.length - 1) {
      // trigger filter applied analytics
      onFilterApplied({
        question: questionsRef.current,
        answer: answersRef.current,
        filters: `${answersRef.current}${temporaryAnswers}`,
      })

      onGetRegimeClick({
        answers: {
          ...answers,
          [`answers${activeTabIndex + 1}`]: temporaryAnswers,
        },
        type: category,
      })
      updateAnswersData({[activeTabData.category]: [...temporaryAnswers]})
      toggleResultsModal()
      onClose()
      return
    }

    //setting refs for analytics
    questionsRef.current = [...questionsRef.current, activeTabData.question]
    answersRef.current = [...answersRef.current, ...temporaryAnswers]

    setAnswers((data) => ({
      ...data,
      [`answers${activeTabIndex + 1}`]: temporaryAnswers,
    }))
    updateAnswersData({[activeTabData.category]: [...temporaryAnswers]})
    setTemporaryAnswers([])
    setActiveTabIndex((activeTabIndex) => activeTabIndex + 1)
  }, [
    activeTabData.category,
    activeTabData?.question,
    activeTabIndex,
    answers,
    onClose,
    onFilterApplied,
    onGetRegimeClick,
    questions?.length,
    setActiveTabIndex,
    setAnswers,
    setTemporaryAnswers,
    temporaryAnswers,
    toggleResultsModal,
    updateAnswersData,
    questionsRef,
    answersRef,
    category,
  ])

  const onResetClick = useCallback(() => {
    setTemporaryAnswers([])
  })

  if (!questions.length) {
    return null
  }

  return (
    <ModalBackground clickHandler={onClose}>
      <GetRegimeQuestionsContainer onClick={handleContainerClicked}>
        <div className="tabs-container">
          {questions.map((question, index) => {
            const isTabCompleted = index < activeTabIndex

            return (
              <div
                data-active={isTabActive(index)}
                data-completed={isTabCompleted}
                key={question.category}
                className="tab"
              >
                {question.category}
                {isTabCompleted && (
                  <FontAwesomeIcon className="check-icon" icon={faCheck} />
                )}
              </div>
            )
          })}
        </div>

        <div className="question-container">
          <h2 className="question">{activeTabData.question}</h2>
          <p className="hint">{activeTabData.hint}</p>
          <div className="options">
            {activeTabData.options.map((option) => (
              <span
                key={option}
                className="option"
                data-selected={isOptionSelected(option)}
                onClick={() => handleOptionClicked(option)}
              >
                {option}
              </span>
            ))}
          </div>
          <button
            disabled={isButtonDisabled}
            className="next-button"
            onClick={handleNextButtonClicked}
          >
            {activeTabData.buttonLabel}
          </button>
          <button
            onClick={onResetClick}
            className="reset-button"
            disabled={isButtonDisabled}
          >
            Reset Selections
          </button>
        </div>
      </GetRegimeQuestionsContainer>
    </ModalBackground>
  )
}

export default GetRegimeQuestions
