import styled from 'styled-components'

export const CategoryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--brand-primary-silver-color);

  .spacer {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
  }

  ${({order}) => {
    let sectionStyles = ''

    order.forEach((item, index) => {
      const isLastSection = index === order.length - 1

      if (isLastSection) {
        sectionStyles += `[data-section='${item}'] {order: ${index}; margin-bottom: 0 !important}`
        return
      }

      sectionStyles += `[data-section='${item}'] {order: ${index};}`
    })

    return sectionStyles
  }}
`
