import styled from 'styled-components'

export const TestimonialCardContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  background-color: var(--brand-primary-silver-color);
  height: 250px;
  max-width: 800px;

  .user-image-container {
    width: 40%;

    .user-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      aspect-ratio: 27/40;
    }
  }

  .user-data {
    padding: 16px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .quote-icon {
    width: 28px;
    height: 24px;
    object-fit: cover;
  }

  .testimonial {
    font-size: 12px;
    line-height: 16px;
    color: var(--category-primary-dark-color);
    font-weight: var(--brand-font-weight-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }

  .user-name-container {
    font-weight: var(--brand-font-weight-normal);
    color: var(--category-primary-color);
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .verified-icon {
    width: 12px;
    height: 12px;
    object-fit: cover;
    margin-left: 6px;
  }

  @media screen and (min-width: 768px) {
    height: 400px;
    border-radius: 28px;

    .user-image-container {
      width: 30%;
    }

    .user-data {
      width: 70%;
      padding: 30px 40px;
    }

    .quote-icon {
      width: 50px;
      height: 41px;
    }

    .testimonial {
      font-size: 24px;
      line-height: 32px;
    }

    .user-name-container {
      font-size: 24px;
    }

    .verified-icon {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }
`
