import styled from 'styled-components'

export const StyledFeelingConfusedDesktopWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;

  .feeling-confused__container {
    padding-top: 20px;
    width: 100%;
    position: relative;
  }
  .feeling-confused__data-container {
    background: var(--category-primary-bg1-color);
    height: 155px;
    display: flex;
    border-radius: 16px 16px 0px 0px;
    align-items: center;
    justify-content: center;
    padding-right: 40px;
    ${({cardTopBackground}) =>
      !!cardTopBackground && `background: ${cardTopBackground}`}
  }
  .feeling-confused__image {
    max-height: 264px;
    position: absolute;
    bottom: 0%;
    left: 30px;
  }
  .feeling-confused__title {
    margin-left: auto;
    padding-right: 20px;
    font-weight: var(--brand-font-weight-black);
    font-size: 28.2333px;
    line-height: 39px;
    color: var(--category-primary-color);
  }
  .feeling-confused__arrow {
    max-height: 42px;
    cursor: pointer;
  }
  .feeling-confused__strip {
    width: 100%;
    background: var(--category-primary-color);
    border-radius: 0px 0px 16px 16px;
    display: flex;
    min-height: 30px;
  }
`
