import noop from 'lodash/noop'
import React from 'react'

import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import Typography from '../../../../ComponentsV2/common/Typography/Typography'
import {StyledFeelingConfusedMobileWrapper} from './FeelingConfusedMobile.styles'

const FeelingConfusedMobile = (props) => {
  const {inFirstFold} = props
  const {
    tags = [],
    arrowImageMobile = '',
    arrowImageAltTextMobile = '',
    cardTopBackground = '',
    analytics = {},
  } = props.state || {}

  const {onTagClick = noop} = props.actions || {}

  if (!tags?.length) {
    return null
  }

  return (
    <StyledFeelingConfusedMobileWrapper cardTopBackground={cardTopBackground}>
      {tags.map((tag) => {
        const {
          headingLevel = 2,
          subHeadingLevel,
          title = '',
          image = '',
          altText = '',
          link = '',
        } = tag || {}
        return (
          <div
            key={title}
            className="feeling-confused__container"
            onClick={() => onTagClick(link, analytics, title)}
          >
            <div className="feeling-confused__image-container">
              <OptimizedImage
                source={image}
                aspectWidth="108"
                aspectHeight="151"
                desktopViewWidth="THIRD"
                mobileViewWidth="SMALL"
                altText={altText}
                loading={inFirstFold ? 'eager' : 'lazy'}
                customClassName="feeling-confused__image"
              />
            </div>
            <div className="feeling-confused__title-container">
              <Typography
                text={title}
                variant="cta-label-sm"
                customClassName="feeling-confused__title"
                headingLevel={subHeadingLevel ? subHeadingLevel : headingLevel}
              />
              <OptimizedImage
                source={arrowImageMobile}
                aspectWidth="30"
                aspectHeight="20"
                desktopViewWidth="TINY"
                mobileViewWidth="TINY"
                altText={arrowImageAltTextMobile}
                loading="lazy"
                customClassName="feeling-confused__arrow"
              />
            </div>
          </div>
        )
      })}
    </StyledFeelingConfusedMobileWrapper>
  )
}
export default FeelingConfusedMobile
