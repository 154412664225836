import styled from 'styled-components'

export const StyledChooseYourConcerWrapper = styled.div`
  max-width: 1124px;
  margin: auto auto 60px auto;
  padding: 0 16px;
  width: 100%;

  .choose-you-concern__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  .choose-your-concern__card {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--brand-primary-white-color);
  }
  .choose-your-concern__image {
    object-fit: cover;
    width: 100%;
    min-height: 160px;
  }
  .choose-your-concern__heading {
    padding: 10px 14px;
  }
  .choose-your-concern__title {
    color: var(--category-primary-color);
    margin-bottom: 10px;
  }
  .choose-your-concern__title-text {
    display: inline;
    padding-right: 12px;
  }
  .choose-your-concern__subtitle {
    color: var(--brand-primary-grey-color);
  }

  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;
    padding: 0px;

    .choose-you-concern__grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: stretch;
    }
    .choose-your-concern__card {
      width: 100%;
      border-radius: 28px;
    }
    .choose-your-concern__image {
      width: 100%;
      height: 277px;
    }
    .choose-your-concern__heading {
      padding: 16px 24px;
    }
    .choose-your-concern__title-text {
      padding-right: 28px;
    }
  }
`
