import styled from 'styled-components'

export const EmptyResultsText = styled.div`
  p {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 1.2;
    padding: 0 20px;
    margin: 0;
  }

  a {
    font-weight: var(--brand-font-weight-heavy);
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    p {
      font-size: 20px;
      padding: 0 40px;
    }
  }
`
