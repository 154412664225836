import styled from 'styled-components'

export const StyledWrapper = styled.div`
  max-width: 1124px;
  margin: auto;
  width: 100%;
  overflow: hidden;
  margin-bottom: 60px;
  padding: 0 16px 40px 0;

  .image-gallery-bullets {
    position: absolute;
    bottom: -32px;
  }
  .image-gallery-bullets-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-gallery-bullet {
    border: none;
    background-color: black;
    width: 6px;
    height: 6px;
    box-shadow: none;
    margin-left: 9px;
  }
  .image-gallery-bullet:first-child {
    margin-left: 0;
  }
  .image-gallery-bullet.active {
    background-color: var(--category-primary-color);
    border: none;
    width: 12px;
    height: 12px;
  }

  .img-gallery {
    width: 70%;
    margin: auto;

    .image-gallery-slides {
      overflow: visible;
      max-width: 100vw;
      display: flex;
    }
    .image-gallery-slide {
      padding: 0
        min(40px, calc(12px + (40 - 12) * ((100vw - 360px) / (1440 - 360))));
      display: flex;
      height: 100%;
    }

    .image-gallery-slide.center {
      padding: 0;
    }

    .image-gallery-slide.center .video_container {
      height: min(
        572px,
        calc(180px + (572 - 180) * ((100vw - 360px) / (1440 - 360)))
      );
    }

    .image-gallery-slide.center .stopper {
      display: none;
    }
    .stopper {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .play-icon {
    background-color: transparent !important;
  }

  @media (min-width: 768px) {
    margin-bottom: 120px;
    padding: 0 0 60px 0;

    .testimonial_title {
      font-size: 48px;
      margin-bottom: 60px;
    }

    .image-gallery-bullets {
      bottom: -46px;
    }

    .image-gallery-bullet {
      width: 10px;
      height: 10px;
    }
    .image-gallery-bullet.active {
      width: 20px;
      height: 20px;
    }
    .img-gallery {
      width: 1095px;
      max-width: 1200px;
      margin: auto;

      .image-gallery-swipe {
        width: 1095px;
        overflow: hidden;
      }
      .image-gallery-slides {
        width: 410px;
        margin: auto;
        overflow: visible;
        margin: 0 auto;
      }

      .slider-nav {
        &.left {
          left: -30px;
        }
        &.right {
          right: 30px;
        }
      }
      .image-gallery-slide.image-gallery-slide {
        padding: -20px;
        width: 365px;
        /* transform: translate3d(80%, 0px, 0px) !important; */
        /* :first-child {
          transform: translate3d(80%, 0px, 0px) !important;
        } */
        /* display: none !important; */
        .video_container {
          height: 349px !important;
          width: 349px;
          margin-top: 0;
          margin-bottom: 0;
          .play-icon {
            width: 60px;
            height: 60px;
          }
        }
        &.right {
          display: inherit !important;
          /* transform: translate3d(622px, 0px, 0px) !important; */
          padding: 0 65px 0 0;

          .video_container {
            .video-embed {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
        &.left {
          display: inherit !important;
          padding: 0 0 0 52px;
          .video_container {
            .video-embed {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
        &.center {
          display: inherit !important;
          z-index: 2;
          width: 365px;
          /* transform: translate3d(285px, 0px, 0px) !important; */

          padding: 0;

          .video_container {
            height: 407px !important;
            width: 407px !important;

            .play-icon {
              width: 97px;
              height: 97px;
            }
          }
        }
      }
    }
  }
`
