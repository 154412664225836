import styled from 'styled-components'

export const StyledFeelingConfusedMobileWrapper = styled.div`
  display: flex;
  gap: 26px;
  justify-content: center;

  .feeling-confused__container {
    padding-top: 35px;
    width: 100%;
  }
  .feeling-confused__image-container {
    background: var(--category-primary-bg1-color);
    position: relative;
    height: 82px;
    display: flex;
    justify-content: center;
    border-radius: 16px 16px 0px 0px;

    ${({cardTopBackground}) =>
      !!cardTopBackground && `background: ${cardTopBackground}`}
  }
  .feeling-confused__image {
    max-height: 151px;
    position: absolute;
    bottom: 0;
  }
  .feeling-confused__title-container {
    width: 100%;
    background: var(--brand-primary-black-color);
    color: var(--brand-primary-white-color);
    border-radius: 0px 0px 16px 16px;
    display: flex;
    padding: 16px 10px;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .feeling-confused__title {
    padding-right: 8px;
  }
  .feeling-confused__arrow {
    max-height: 20px;
    position: absolute;
    right: 0;
    padding-right: 10px;
  }
`
