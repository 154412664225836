import React, {useState, useCallback, useEffect} from 'react'
import noop from 'lodash/noop'

import {GetRegimeContainer} from './GetRegime.styles'
import {GetRegimeCard} from './GetRegimeCard'
import {GetRegimeQuestions} from './GetRegimeQuestions'
import {GetRegimeResults} from './GetRegimeResults'
import isEmpty from 'lodash/isEmpty'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {useToggleBodyScroll} from 'src/hooks/useToggleBodyScroll'

function GetRegime(props) {
  const {state = {}, actions = {}} = props || {}
  const {
    questions = [],
    isRegimeLoading = false,
    regime = {},
    sectionName = 'getRegime',
    totalItems = 0,
    category = '',
    regimeFallback,
    ...rest
  } = state || {}
  const {
    onAddToCart = noop,
    onCartClick = noop,
    onGetRegimeClick = noop,
    onGetRegimeCardClick = noop,
    onFilterApplied = noop,
    onProductClick = noop,
  } = actions || {}

  const {disableBodyScroll, enableBodyScroll} = useToggleBodyScroll()

  const [showQuestionsModal, setShowQuestionsModal] = useState(false)
  const [showResultsModal, setShowResultsModal] = useState(false)
  // state to store user selected answers with the tab label for the results screen
  const [selectedAnswers, setSelectedAnswers] = useState({})

  const toggleQuestionModal = useCallback(
    () => setShowQuestionsModal((isShow) => !isShow),
    []
  )

  const toggleResultsModal = useCallback(
    () => setShowResultsModal((isShow) => !isShow),
    []
  )

  const updateAnswersData = useCallback(
    (data) =>
      setSelectedAnswers((args) => ({
        ...args,
        ...data,
      })),
    []
  )

  const onCardClick = useCallback(() => {
    toggleQuestionModal()

    //for analytics
    onGetRegimeCardClick()
  }, [onGetRegimeCardClick, toggleQuestionModal])

  useEffect(() => {
    if (showQuestionsModal || showResultsModal) disableBodyScroll()
    else enableBodyScroll()
  }, [
    showQuestionsModal,
    showResultsModal,
    disableBodyScroll,
    enableBodyScroll,
  ])

  if (isEmpty(state) || isEmpty(questions)) {
    return null
  }

  return (
    <GetRegimeContainer data-section={sectionName}>
      <GetRegimeCard state={rest} actions={{onCardClick}} />
      {showQuestionsModal && (
        <GetRegimeQuestions
          state={{questions, category}}
          actions={{
            onClose: toggleQuestionModal,
            onGetRegimeClick,
            toggleResultsModal,
            updateAnswersData,
            onFilterApplied,
          }}
        />
      )}
      {showResultsModal && (
        <GetRegimeResults
          state={{
            regime,
            selectedAnswers,
            totalItems,
            isLoading: isRegimeLoading,
            regimeFallback,
            sectionName,
          }}
          actions={{
            onAddToCart,
            onCartClick,
            onCloseClick: toggleResultsModal,
            onProductClick,
          }}
        />
      )}
    </GetRegimeContainer>
  )
}

export default withCustomErrorBoundary(GetRegime, 'GetRegime')
