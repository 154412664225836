import styled from 'styled-components'

export const FeelingConfusedContainer = styled.div`
  max-width: 1124px;
  margin: auto auto 60px auto;
  width: 100%;
  padding: 0 16px;

  &[data-forpage='homepage'] {
    margin: auto auto 40px auto;
  }

  .homepage-feeling-confused-title {
    color: var(--brand-font-default-color);
  }

  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;
    padding: 0px;

    &[data-forpage='homepage'] {
      margin: auto auto 100px auto;
    }

    .homepage-feeling-confused-title {
      width: 100%;
    }
  }
`
