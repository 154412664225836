import styled from 'styled-components'

export const BenefitCardsWrapper = styled.div`
  max-width: 1124px;
  margin: auto auto 60px auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .benefits-desktop-header {
    font-family: var(--brand-font-family);
    font-style: normal;
    font-weight: var(--brand-font-weight-normal);
    font-size: 40px;
    line-height: 55px;
    text-transform: uppercase;
    color: var(--category-primary-dark-color);
    margin-bottom: 30px;

    span {
      font-weight: var(--brand-font-weight-black);
    }
  }

  .benefits-desktop-card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @media (min-width: 768px) {
    margin: auto auto 120px auto;
    padding: 0px;
  }
`
