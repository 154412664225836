import React from 'react'

import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {TestimonialCardContainer} from './TestimonialCard.styles'

function TestimonialCard(props) {
  const {
    image = '',
    testimonial = '',
    name = '',
    age = '',
    verifiedIcon = '',
    quoteIcon = '',
    altText = '',
  } = props.state || {}

  return (
    <TestimonialCardContainer>
      <div className="user-image-container">
        <OptimizedImage
          source={image}
          aspectWidth={27}
          aspectHeight={40}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          alt={altText}
          loading="lazy"
          customClassName="user-image"
        />
      </div>

      <div className="user-data">
        <OptimizedImage
          source={quoteIcon}
          aspectWidth={28}
          aspectHeight={24}
          desktopViewWidth="SMALL"
          mobileViewWidth="SMALL"
          alt="large left quote icon"
          loading="lazy"
          customClassName="quote-icon"
        />
        <p className="testimonial">{testimonial}</p>

        <span className="user-name-container">
          <b>{name}</b>, {age}
          <OptimizedImage
            source={verifiedIcon}
            aspectWidth={12}
            aspectHeight={12}
            desktopViewWidth="TINY"
            mobileViewWidth="TINY"
            alt="verified icon"
            loading="lazy"
            customClassName="verified-icon"
          />
        </span>
      </div>
    </TestimonialCardContainer>
  )
}

export default TestimonialCard
