import styled from 'styled-components'

export const StyledBlogListingWrapper = styled.div`
  max-width: 1124px;
  margin: auto auto 60px auto;
  padding: 0 16px;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding: 0px;
    margin: auto auto 120px auto;
  }
`
