import {useCallback, useEffect, useMemo, useState} from 'react'
import isEmpty from 'lodash/isEmpty'
import ImageGallery from 'react-image-gallery'

import {StyledWrapper} from './styles'
import {RenderItem} from './index'
import {
  StyledLeftNav,
  StyledRightNav,
} from '../../../ComponentsV2/Screens/HomePage/FirstFoldCarousel'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import {Section, NextArrow, PrevArrow} from '../../../ComponentsV2/common'

const VideoReviews = (props) => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const {state = {}, isMobile = false} = props
  const {
    title = '',
    subtitle = '',
    sectionName = 'videoReviews',
    videos = [],
    playIconUrl,
  } = state

  useEffect(() => {
    if (videos.length === 1) {
      setCurrentIndex(0)
    }
  }, [])

  const handleIndexChange = useCallback((index) => {
    setCurrentIndex(index)
  }, [])

  const mappedVideos = useMemo(() => {
    return videos
      ? videos.map((video, index) => {
          return {
            ...video,
            playIconUrl,
            renderItem: (videoItem) => {
              return (
                <RenderItem {...videoItem} isActive={index === currentIndex} />
              )
            },
          }
        })
      : []
  }, [currentIndex, playIconUrl, videos])

  if (isEmpty(videos)) return null

  const isNavShow = !isMobile

  return (
    <StyledWrapper data-section={sectionName}>
      <Section state={{title, subtitle}} />
      <ImageGallery
        additionalClass="img-gallery"
        showNav={isNavShow}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        infinite={false}
        showBullets={true}
        items={mappedVideos}
        onSlide={handleIndexChange}
        renderLeftNav={(onClick, disabled) => {
          return (
            <StyledLeftNav
              data-disabled={disabled}
              onClick={onClick}
              className="slider-nav left"
            >
              <PrevArrow />
            </StyledLeftNav>
          )
        }}
        renderRightNav={(onClick, disabled) => {
          return (
            <StyledRightNav
              data-disabled={disabled}
              onClick={onClick}
              className="slider-nav right"
            >
              <NextArrow />
            </StyledRightNav>
          )
        }}
        startIndex={currentIndex}
      />
    </StyledWrapper>
  )
}

export default Responsive(VideoReviews)
