import noop from 'lodash/noop'

import HeadingComponent from '../../../../ComponentsV2/common/HeadingComponent'
import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {StyledFeelingConfusedDesktopWrapper} from './FeelingConfusedDesktop.styles'

const FeelingConfusedDesktop = (props) => {
  const {inFirstFold = false} = props
  const {
    tags = [],
    arrowImageDesktop = '',
    arrowImageAltTextDesktop = '',
    cardTopBackground,
    analytics = {},
  } = props.state || {}

  const {onTagClick = noop} = props.actions || {}

  if (!tags?.length) {
    return null
  }

  return (
    <StyledFeelingConfusedDesktopWrapper cardTopBackground={cardTopBackground}>
      {tags.map((tag) => {
        const {
          headingLevel = 2,
          title = '',
          image = '',
          altText = '',
          link = '',
        } = tag || {}
        return (
          <div key={title} className="feeling-confused__container">
            <OptimizedImage
              source={image}
              aspectWidth="189"
              aspectHeight="264"
              desktopViewWidth="THIRD"
              mobileViewWidth="THIRD"
              altText={altText}
              loading={inFirstFold ? 'eager' : 'lazy'}
              customClassName="feeling-confused__image"
            />
            <div className="feeling-confused__data-container">
              <HeadingComponent
                headingLevel={headingLevel}
                customClassName="feeling-confused__title"
                label={title}
              />
              <OptimizedImage
                source={arrowImageDesktop}
                aspectWidth="42"
                aspectHeight="42"
                desktopViewWidth="TINY"
                mobileViewWidth="TINY"
                altText={arrowImageAltTextDesktop}
                loading="lazy"
                customClassName="feeling-confused__arrow"
                onClick={() => onTagClick(link, analytics, title)}
              />
            </div>
            <div className="feeling-confused__strip"></div>
          </div>
        )
      })}
    </StyledFeelingConfusedDesktopWrapper>
  )
}
export default FeelingConfusedDesktop
