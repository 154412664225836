import React, {useMemo, useState, useCallback} from 'react'
import noop from 'lodash/noop'

import {SafeAndEffectiveIngredientCard} from './SafeAndEffectiveIngredientCard'
import {SafeAndEffectiveIngredientsContainer} from './SafeAndEffectiveIngredients.styles'
import {CustomSlider, Section} from '../../../ComponentsV2/common'
import Modal from '../../../ComponentsV2/common/Modal'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import isEmpty from 'lodash/isEmpty'

function SafeAndEffectiveIngredients(props) {
  const {state = {}, actions = {}} = props || {}

  const {
    title = '',
    subtitle = '',
    cards = [],
    sectionName = 'ingredients',
  } = state || {}
  const {onViewProductClick = noop} = actions || {}

  const [showDescriptionCardIndex, setShowDescriptionCardIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const sliderSettings = useMemo(() => {
    return {
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    }
  }, [])

  const descriptionToShow = useMemo(() => {
    if (showDescriptionCardIndex !== null) {
      return cards[showDescriptionCardIndex].description
    }

    return ''
  }, [cards, showDescriptionCardIndex])

  const changeShowDescriptionCardIndex = useCallback((index) => {
    setShowDescriptionCardIndex(index)
    setShowModal(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setShowModal(false)
    setShowDescriptionCardIndex(null)
  }, [])

  if (isEmpty(cards)) {
    return null
  }

  return (
    <SafeAndEffectiveIngredientsContainer data-section={sectionName}>
      <Section state={{title, subtitle}} />
      <CustomSlider sliderSettings={sliderSettings}>
        {cards.map((card, index) => (
          <SafeAndEffectiveIngredientCard
            key={card.name}
            state={{...card, index}}
            actions={{
              changeShowDescriptionCardIndex,
              onButtonClick: onViewProductClick,
            }}
          />
        ))}
      </CustomSlider>
      <Modal showModal={showModal} onClose={handleModalClose}>
        <div className="ingredient__modal-content">{descriptionToShow}</div>
      </Modal>
    </SafeAndEffectiveIngredientsContainer>
  )
}

export default withCustomErrorBoundary(
  SafeAndEffectiveIngredients,
  'SafeAndEffectiveIngredients'
)
