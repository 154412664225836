import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React, {useCallback} from 'react'

import OptimizedImage from '../../../../ComponentsV2/common/OptimizedImage'
import {SafeAndEffectiveIngredientCardContainer} from './SafeAndEffectiveIngredientCard.styles'

const SafeAndEffectiveIngredientCard = (props) => {
  const {
    ingredient = '',
    description = '',
    showMore = '',
    heading = '',
    name = '',
    button = '',
    image = '',
    altText = '',
    link = '',
    index = '',
  } = props.state || {}

  const {onButtonClick = noop, changeShowDescriptionCardIndex = noop} =
    props.actions || {}

  const handleOnShowMoreClick = useCallback(
    () => changeShowDescriptionCardIndex(index),
    [index, changeShowDescriptionCardIndex]
  )

  const handleOnButtonClick = useCallback(
    () => onButtonClick(link),
    [onButtonClick, link]
  )

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <SafeAndEffectiveIngredientCardContainer>
      <div className="ingredient__heading-container">
        <div className="ingredient__heading">{ingredient}</div>
        <div className="ingredient__description">
          {description.substring(0, 110)}
          {description.length > 110 && (
            <span
              className="ingredient__show-more"
              onClick={handleOnShowMoreClick}
            >
              {showMore}
            </span>
          )}
        </div>
      </div>
      <div className="ingredient__in-product">
        <div className="ingredient__in-product-data-container">
          <div className="ingredient__in-product-heading">{heading}</div>
          <div className="ingredient__in-product-name">{name}</div>
          <button
            className="ingredient__in-product-button"
            onClick={handleOnButtonClick}
          >
            <span className="ingredient__in-product-button-text">{button}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <div className="ingredient__in-product-image-container">
          <OptimizedImage
            source={image}
            aspectHeight="144"
            desktopViewWidth="THIRD"
            mobileViewWidth="THIRD"
            alt={altText}
            loading="lazy"
            customClassName="ingredient__in-product-image"
          />
        </div>
      </div>
    </SafeAndEffectiveIngredientCardContainer>
  )
}
export default SafeAndEffectiveIngredientCard
