import styled from 'styled-components'

export const SafeAndEffectiveIngredientsContainer = styled.div`
  max-width: 1124px;
  margin: auto auto 60px auto;
  padding: 0 16px;
  width: 100%;

  .ingredient__modal-content {
    color: var(--brand-primary-black-color);
    background: var(--brand-primary-white-color);
    opacity: 0.8;
    font-weight: var(--brand-font-weight-medium);
    font-size: 14px;
    line-height: 20px;
    padding: 16px 0px;
  }

  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;
    padding: 0px;

    .ingredient__modal-content {
      font-size: 18px;
      line-height: 26px;
    }
  }
`
